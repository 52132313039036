import React from 'react';
import { Box, Typography } from '@mui/material';
import LazyImage from '../../../Layouts/LazyImage';

const USPCard = ({ data }) => {
  return (
    <Box
      sx={{
        my: 5,
        px: {
          md: 5,
        },
        mx: 'auto',
      }}
      maxWidth={'850px'}
    >
      <LazyImage
        sx={{ minHeight: { xs: 200, sm: 300, md: 500 } }}
        src={data.img}
        sizes={sizes}
        alt={data?.title}
      />

      <Box p={2} borderRadius={2} maxWidth="780px" width="100%" mx="auto">
        <Typography gutterBottom variant="h3" align="center">
          {data?.title}
        </Typography>

        <Typography variant="body1" align="center">
          {data?.para}
        </Typography>
      </Box>
    </Box>
  );
};

const sizes = {
  xxl: [912, 761],
  sm: [315, 262],
};
export default USPCard;
