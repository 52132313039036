import React from 'react';
import { Box, Container, Typography } from '@mui/material';

import USPCard from './USPCard';

const USPSection = ({ data }) => {
  return (
    <Container component={Box} my={{ xs: 3 }}>
      <Typography variant="h2" align="center" paragraph>
        {data?.title}
      </Typography>
      <Box mb={6} />

      <Box
        py={2}
        style={{
          backgroundImage: `url(${process.env.NEXT_PUBLIC_CDN}/images/icons/dot.png)`,
        }}
      >
        {data?.uspCards.map((data, key) => (
          <USPCard data={data} key={key} />
        ))}
      </Box>
    </Container>
  );
};

export default USPSection;
